.dHQHGmImg {
    width: 50%;
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 600px) {
    .dHQHGmImg {
        width: 100%;
        height: 100vh;
        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}