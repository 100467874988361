@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback);
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans);
body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.style_CardBodyImg__3YtVF{
    height: 80px;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    /* transform: scale(1.4); */
    /* transform-origin: 100% 0; */
    align-items: center;
}
.style_CardBodyImg__3YtVF:hover {
    -webkit-transform: scale(2.5);
            transform: scale(2.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.style_CardBodyImgTd__3i9Px{
    width: 10%;
    text-align: center;
}

.style_CardBodyEdit__3ZykA{
    position: relative;
    width: 3%;
    text-align: center;
    align-items: center;
}

.style_CardIconCenter__3w7mP {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


.style_loader__119FJ {
  color: #1ad15a;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__2SHM2 1.7s infinite ease, style_round__2Sn8P 1.7s infinite ease;
  animation: style_load6__2SHM2 1.7s infinite ease, style_round__2Sn8P 1.7s infinite ease;
}
@-webkit-keyframes style_load6__2SHM2 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_load6__2SHM2 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes style_round__2Sn8P {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes style_round__2Sn8P {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.style_loader__1OvQs {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__1lYFe 1.7s infinite ease, style_round__30evF 1.7s infinite ease;
    animation: style_load6__1lYFe 1.7s infinite ease, style_round__30evF 1.7s infinite ease;
  }
  @-webkit-keyframes style_load6__1lYFe {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_load6__1lYFe {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes style_round__30evF {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes style_round__30evF {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
.style_marginRight__TAJsM {
  margin: 0px 10px 0px 0px;
}
.style_errorMsg__JfOqZ {
  margin-left: 10px;
  color: red;
}

.style_inputImage__1dxEw {
  height: 50px;
}
.style_productPrice__s_Zc- {
  display: flex;
  flex-direction: row;
}
.style_productPrice__s_Zc- input {
  width: 200px;
}

.style_btnHidden__3dHe5 {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.style_divBtnHide__1W0dh {
  display: flex;
  flex-direction: row;
}
.style_btnHidden__3dHe5 i {
  color: darkgreen;
  font-size: 14px;
}
.style_colorred__1h0Ia{
  font-weight: bold;
  color: #CC0000;
}
.style_colorgreen__1KbUD{
  font-weight: bold;
  color: #00CC00;
}
.style_CardBodyEdit__otiWB {
  position: relative;
  width: 3%;
  text-align: center;
  align-items: center;
}

.style_card-body__2ePOm {
  align-items: center;
}

.style_CardIconCenter__1iDhw {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.style_tdHarahCenter__rpU1c {
  text-align: center;
}
.style_dropDownCenter__2KOaA {
  text-align: center;
}
.style_dropdown-menu__rGmio {
  cursor: hand;
}
.style_dropdown-item__39Fs0 {
  cursor: pointer;
}
.style_btnHidden__2Yk0K {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.style_divBtnHide__3cMyQ {
  display: flex;
  flex-direction: row;
}
.style_btnHidden__2Yk0K i {
  color: darkgreen;
  font-size: 14px;
}
.style_selectCss__3KpfA {
  font-size: 18px;
  background-color: blue;
}
.style_loader__MkQfB {
  color: #1ad15a;
  font-size: 20px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 1% auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__3U8mA 1.7s infinite ease, style_round__1RNgn 1.7s infinite ease;
  animation: style_load6__3U8mA 1.7s infinite ease, style_round__1RNgn 1.7s infinite ease;
}
@-webkit-keyframes style_load6__3U8mA {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_load6__3U8mA {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes style_round__1RNgn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes style_round__1RNgn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.style_textuud__2DLGV {
  text-align: center;
}
.style_tableScroll__2YjO2 {
  overflow: auto;
  max-height:2600;
}

.boldStyle {
  font-weight: bold;
  font-size: 20px;
}

@media screen {
  #printSection {
    display: none;
  }
  .modal-lg {
    width: 500px;
  }
  #spanDate {
    visibility: hidden;
  }
  #labelDate {
    visibility: hidden;
  }
}

@media print {
  #printSection:after {
    content: "©  " attr(data-date) " Be Smart";
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  body * {
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
    visibility: hidden;
  }
  .modal-lg {
    max-width: 80% !important;
  }
  .badge {
    font-size: 60px;
  }
  #closeModal {
    visibility: hidden;
    display: none;
  }
  #printModal {
    display: none;
    visibility: hidden;
  }
  th {
    font-size: 60px;
    font-weight: bold;
  }
  td {
    inline-size: 150px;
    overflow-wrap: break-word;
    font-size: 60px;
    font-weight: bold;
  }
  label {
    font-size: 60px;
    font-weight: bold;
  }
  #spanDate {
    visibility: visible;
  }
  #labelDate {
    visibility: visible;
  }
  .boldStyle {
    font-weight: bold;
    font-size: 60px;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #orderSection {
    display: none;
    visibility: hidden;
  }
}

.style_CardBodyImg__YPeJa {
  width: 30px;
  height: auto;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  /* transform: scale(1.4); */
  /* transform-origin: 100% 0; */
  align-items: center;
}
.style_CardBodyImg__YPeJa:hover {
  -webkit-transform: scale(
    2.5
  );
          transform: scale(
    2.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.style_CardBodyImgTd__ajFeG {
  width: 10%;
  text-align: center;
}

.style_CardBodyEdit__3HkER {
  position: relative;
  width: 3%;
  text-align: center;
  align-items: center;
}

.style_CardIconCenter__2_CCe {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.style_loader__3kW8k {
  color: #1ad15a;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__-hmRu 1.7s infinite ease, style_round__3srb9 1.7s infinite ease;
  animation: style_load6__-hmRu 1.7s infinite ease, style_round__3srb9 1.7s infinite ease;
}
@-webkit-keyframes style_load6__-hmRu {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_load6__-hmRu {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes style_round__3srb9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes style_round__3srb9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.style_marginRight__2eWlz {
  margin: 0px 10px 0px 0px;
}
.style_errorMsg__2r1wl {
  margin-left: 10px;
  color: red;
}

.style_inputImage__3Ns7H {
  height: 50px;
}

button:focus {outline:0;}
::-webkit-scrollbar {
    width: 2px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.style_jVFuuY__3tcfh {
    
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #009e7f;
    padding: 0;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 21px 36px rgba(0, 0, 0, 0.16);
    border: 0;
    outline: 0;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -46px;
    z-index: 99;
}
.style_KDupa__1GAvu {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    padding-left: 5px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
}
.style_KDupa__1GAvu i {
    margin-right: 10px;
}
.style_dCrbXJ__1xb_e {
    width: auto;
    height: 35px;
    min-width: 80px;
    overflow: hidden;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #009e7f;
    margin: 0 10px 10px;
}
@media(max-width: 575px) { 
    .style_jVFuuY__3tcfh {
        top: auto;
        flex-direction: row;
        box-sizing: border-box;
        font-family: Lato, sans-serif;
        margin: 0;
        -webkit-appearance: none;
                appearance: none;
        -webkit-box-align: center;
        border-radius: 60px;
        width: calc(100% - 60px);
        height: 45px;
        padding: 2px 2px 2px 30px;
        bottom: 30px;
        right: 30px;
    }
    .style_KDupa__1GAvu{
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        font-family: Lato,sans-serif;
        font-size: 12px;
        padding: 0px;
    }
    .style_dCrbXJ__1xb_e{
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        border-radius: 28px;
        font-family: Lato,sans-serif;
        font-size: 12px;
        width: 90px;
        height: 41px;
        margin-left: auto;
        margin-right: 0;
    }
}

.style_bOpSCc__DYZb_ {
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom: 1px solid;
    border-bottom-color: #F7F7F7;
    display: flex;
    align-items: center;
}
.style_bkLFHu__3emJg {
    display: flex;
    font-size: 15px;
    font-weight: 700;
    border-radius: 200px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    width: 30px;
    height: 90px;
    flex-direction: column-reverse;
    background-color: #F7F7F7;
    color: #0D1136;
}
.style_cbNtye__2eo-t {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    cursor: pointer;
    color: #77798C;
}
.style_kyNexk__ZQymz {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 0 5px;
}
.style_djMkq__37aGj {
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 0px;
    line-height: 1.5;
    text-align: center;
}
.style_ewWbZD__3f-q8 {
    color: #009E7F;
    font-size: 12px;
}
.style_ewWbZD2__igFzW {
    color: #0D1136;
    font-size: 14px;
}
.style_gKpPoA__3Nu0V {
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    margin-bottom: 5px;
}
.style_cbwArM__-lm2N {
    color: #009E7F;
    margin-left: auto;
    font-size: 12px;
}
.style_cbwArM2__sY9KW {
    color: #009E7F;
    margin-left: auto;
    font-size: 14px;
}
.style_ijpSFz__1lifs {
    padding: 0px;
    border: 0;
    outline: 0;
    margin-left: 5px;
    cursor: pointer;
    color: rgba(0,0,0,0.25);
    transition: all 0.4s ease;
    background-color: transparent;
}
.style_ijpSFz__1lifs:hover {
    color: #ea4d4a;
}
.style_flexcolumn__fomti{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.style_flexrow__2N7e8{
    display: flex;
    flex-direction: row;
    margin: auto;
}
.style_margintop2__Wu8bj{
    margin-top: 2px;
}
.style_fFQivv__1s4rH {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}
.style_productpercent__1ImFI {
    font-size: 10px;
    font-weight: 400;
    color: #FFB369;
}
.style_colorred__2URKb{
    color: red;
}
/* Сагсны бие */
.style_lamVFu__1I-u_{
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    box-sizing: border-box;
    font-family: Lato,sans-serif;
    margin: 0;
    width: 420px;
    height: 90vh;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    z-index: 100;
    box-shadow: 0 21px 36px rgba(0,0,0,0.16);
    transition: all 0.1s ease-in-out;
    /* display: flex; */
    right: 0;
}
.style_jKfBPg__1i4KV{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-sizing: content-box;
    height: 100%;
    width: 100%;
}
.style_gkbJjA__1ljWJ{
    margin: 0;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    justify-content: space-between;
}
.style_fhKndd__3GOia{
    box-sizing: border-box;
    margin: 0;
    display: inline-flex;
    align-items: center;
    color: #009E7F;
}
.style_fhKndd__3GOia span {
    font-weight: 700;
    color: #009E7F;
    padding-left: 10px;
}
.style_black__1RaQc{
    font-weight: 700;
    color: #0D1136;
}
.style_red__2Ufxu{
    font-weight: 700;
    color: red;
}
.style_kWBUWu__1U524{
    box-sizing: border-box;
    margin: 0;
    -webkit-appearance: none;
            appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    outline: 0;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: rgba(0,0,0,0.5);
    top: auto;
    position: relative;
    background-color: transparent;
}
.style_kWBUWu__1U524:hover{
    color: #ea4d4a;
}
.style_cartScrollbar__1iWbm{
    box-sizing: border-box;
    margin: 0;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    overflow: auto !important;
    height: 100%;
    max-height: calc(100vh - 245px);
}
.style_ospadding__1gOVx {
    box-sizing: inherit;
    direction: inherit;
    position: absolute;
    overflow: visible;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
}
.style_iKtYkh__1UeMj {
    width: 100%;
    height: auto;
}
.style_bhHvDf__2d8UO {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    flex-shrink: 0;
}
.style_dQOcYU__2i26W {
    height: 48px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(0, 158, 127);
    padding: 0px;
    border-radius: 48px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left: 15px;
}
/* @media(max-width: 767px){
    .dQOcYU {
        height: 45px;
    }
} */
.style_dQOcYU__2i26W > a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
}
.style_flexcolumn__34lxL{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.style_kVQlLW__DDTdA {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 10px;
}
.style_kVQlLW__DDTdA:hover {
    color: #fff;
}
.style_iDSbKK__2_PoQ {
    height: 46px;
    width: auto;
    padding: 0 20px;
    border-radius: 28px;
    background-color: #ffffff;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #009E7F;
    margin: 0px;
    margin-right: 1px;
}
.style_iDSbKKspan__3dkAL{
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: #009E7F;
    font-size: 12px;
    margin: auto;
}
.style_productpercent__s4VvE {
    font-size: 10px;
    font-weight: 400;
    color: #FFB369;
}
@media(max-width: 575px) { 
    .style_lamVFu__1I-u_{
        /* display: none; */
        /* position:absolute; */
        bottom:0;
        margin:0;
        width:100%;
        height: 60vh;
    }
    .style_cartScrollbar__1iWbm{
        /* max-height: 330px;
        height: 60%; */
    }
}
.style_ModalBackdrop__3c6fv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  --opacity: 0.5;
  background-color: hsla(0,0%,0%,var(--opacity));
  /* overflow: hidden; */
  z-index: 100;
}

.style_CloseSpan__3r5mq{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 17, 54);
    border: 0px;
    outline: 0px;
    box-shadow: none;
    border-radius: 50%;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 100000;
    cursor: pointer;
}

.style_ModalBody__HiLuw{
  opacity: 1;
  padding: 0px;
  max-width: calc(100% - 30px);
  height: auto;
  max-height: calc(100vh - 30px);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 99999;
}

.style_ModalTextArea__3iofC{
  height: 100%;
  width: 100%;
}

.style_ModalObserver__H_rsy{
  left: 0px;
  right: auto;
}
.style_ModalObserver1__1a5xT{
  height: calc(100% + 1px);
  float: left;
}
.style_ModalObserver2__QrYFT{
  width: 1000%;
  height: 1000%;
  min-height: 1px;
  min-width: 1px;
}
.style_bEucZD__1-LZH {
  width: 1020px;
  max-width: 100%;
  height: 100%;
}
.style_dncRqP__3xl66 {
  background-color: rgb(255, 255, 255);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
}
.style_dHQHGm__3CYt0 {
  width: 50%;
  max-width: 50%;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.style_dncRqP__3xl66 * {
  box-sizing: border-box;
}
.style_dapnPR__9tOwi {
  border: 1px solid rgb(241, 241, 241);
  border-radius: 6px;
  margin-right: 20px;
  padding: 0px;
  outline: none;
  width: 70px;
  height: auto;
  overflow: hidden;
}
.style_dapnPR__9tOwi.style_custom-dot--active__1b4T- {
  border: 2px solid rgb(0, 158, 127);
}
.style_dHQHGmImg__2464- {
  width: 100%;
  min-width: auto;
    height: auto;
    position: relative;
    margin: auto;
}
.style_SPnrf__1qM_y {
  width: 50%;
  max-width: 50%;
  border-left: 1px solid rgb(243, 243, 243);
}
.style_ixsiGC__1HSLR {
  padding: 50px;
}
.style_lfdRJ__xy6NW {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.style_fHUdc__yPid2{
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(13, 17, 54);
  line-height: 1.5;
}
.style_fHUdcH__2yGOg {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(13, 17, 54);
  line-height: 1.5;
  display: flex;
}
.style_fHUdcH1__3oXKp {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(0, 158, 127);
  line-height: 1.5;
  display: flex;
  margin-left: 20%;
}
.style_lmanW__2hiYG {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(119, 121, 140);
}
.style_scroll__1eIK0{
  overflow: auto;
  max-height: 200px;
  padding: 5px;
}
.style_kOEjVM__3EnPr {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgb(66, 69, 97);
  line-height: 2;
  text-align: justify;
  margin-bottom: 0px;
}
.style_gIBFsL__2xdQ5 {
  margin-top: 30px;
}
.style_dyPuuD__2e77i {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}
.style_dyPuuD__2e77i:last-child {
  margin-bottom: 0px;
}
.style_AhjRa__3_eqD {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: rgb(13, 17, 54);
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: rgb(247, 247, 247);
  padding: 0px 15px;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_kKdNbW__1jTXJ {
  display: flex;
  flex-direction: column;
}
.style_wGaXT__da4Ax {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0%, 10%, 0% 10%;
}
.style_CIPBX__29qlN {
  font-family: Lato, sans-serif;
  font-size: calc(16px);
  font-weight: 700;
  color: rgb(0, 158, 127);
  margin: auto;
  left: 0;
}
.style_CIPBX1__3O1l7 {
  font-family: Lato, sans-serif;
  font-size: calc(16px);
  font-weight: 700;
  color:black;
  left: 0;
  margin: auto;
}
.style_wgEPt__1jyu3 {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(255, 179, 105);
  font-style: italic;
  padding: 0px 5px;
  overflow: hidden;
  position: relative;
  margin-left: 10px;
}
.style_gurUMl__3DgZ- {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.style_hAARBe__3PWKt {
  padding: 0px 30px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  border-radius: 6px;
  -webkit-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  text-decoration: none;
  border: 0px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 158, 127);
  height: 48px;
  padding-left: 30px;
  padding-right: 30px;
  width: 170px;
  font-family: Lato, sans-serif;
    margin: 0px;
}
.style_vfnVS__14s0g {
  box-sizing: border-box;
  min-width: 0px;
  margin: 0px 8px 0px 0px;
  width: 14.4px;
  height: 12px;
}
.style_buttonSpan__2D_md{
  font-family: Lato, sans-serif;
  margin: 0px;
}
.style_flexrow__lu2lh{
  display: flex;
  flex-direction: row;
}
.style_flexcolumn__3NcIQ{
  display: flex;
  flex-direction: column;
}
.style_descritem__3njsJ{
  width: 98%;
  padding-left: 2%;
  
}
.style_descritemhead__GGQxc{
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  /* border: 1px solid grey; */
}
.style_descritemdetail__3jUtp{
  padding:10px;
  background-color: rgba(200,200,200,0.1);
  border-radius: 20px;
  border: 2px solid #009e7f;
}
.style_arrow__2m0rM{
  font-size: 10px;
}

@media(max-width: 767px) { 
  .style_scroll__1eIK0{
    padding: 5px;
    overflow: hidden;
    max-height: 100%;
    width: 80%;
  }
  .style_CloseSpan__3r5mq{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 17, 54);
    border: 0px;
    outline: 0px;
    box-shadow: none;
    border-radius: 50%;
    position: absolute;
    top: -55px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    cursor: pointer;
  }
  .style_ModalBackdrop__3c6fv {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    --opacity: 0.5;
    background-color: hsla(0,0%,0%,var(--opacity));
  }
  .style_ModalBody__HiLuw{
    -webkit-transform: translateY(-30%) translateY(0px) translateX(-50%);
            transform: translateY(-30%) translateY(0px) translateX(-50%);
    position: absolute;
    bottom: 0px;
    left: 50%;
    padding: 0px;
    width: calc(100% + 1px);
    height: 100%;
    max-height: 100vh;
    max-width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px 20px 0px 0px;
    z-index: 99999;
  }
  .style_dHQHGm__3CYt0 img {
    min-width: auto !important;
    width: 100%;
  }
  .style_dncRqP__3xl66 {
    /* background-color: rgb(255, 255, 255); */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .style_marginvh__kAPof{
    margin-bottom: 20vh;
  }
  .style_bEucZD__1-LZH {
    width: 1020px;
    max-width: 100%;
    height: 100%;
    overflow: auto;
  }
  .style_dHQHGm__3CYt0 {
    width: 100%;
    max-width: 100%;
    /* padding: 20px 0px; */
    /* padding: 15px 30px; */
    order: 0;
    /* margin-left: -3%; */
  }
  .style_SPnrf__1qM_y {
    width: 100%;
    max-width: 100%;
    order: 1;
    border: 0px;
  }
  .style_ixsiGC__1HSLR {
    padding: 0px;
  }
  .style_lfdRJ__xy6NW {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .style_fHUdcH__2yGOg {
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: rgb(13, 17, 54);
    line-height: 1.5;
    display: flex;
    margin-left: 15%;
  }
  .style_fHUdcH1__3oXKp {
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: rgb(0, 158, 127);
    line-height: 1.5;
    display: flex;
    margin-right: 15%;
  }
  .style_lmanW__2hiYG {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(119, 121, 140);
  }
  .style_gIBFsL__2xdQ5 {
    margin-top: 30px;
  }
  .style_dyPuuD__2e77i {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }
  .style_wGaXT__da4Ax {
    margin-left: 25px;
    margin: 0% 10% 0% 10%;
  }
  .style_CIPBX__29qlN {
    font-family: Lato, sans-serif;
    font-size: calc(16px);
    font-weight: 700;
    color: rgb(0, 158, 127);
    margin: auto;
    left: 0;
  }
  .style_CIPBX1__3O1l7 {
    font-family: Lato, sans-serif;
    font-size: calc(16px);
    font-weight: 700;
    color: black;
    margin: auto;
    left: 0;
  }
  .style_kKdNbW__1jTXJ {
    margin-bottom: 50px;
  }
}

/* бүтээгдэхүүн */
.style_cxyAZX__13rih {
    background-color: #fff;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #f3f3f3;
    display: flex;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* .cxyAZX:hover{
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    transform: translateY(-5px);
} */
.style_bLdNZu__1mARh {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    padding: 10px;
    height: 240px;
}
.style_ithsmr__37L3N {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
}
@media screen and (max-width: 1280px){
    .style_bLdNZu__1mARh {
        height: 200px;
    }
}
.style_kKpnwf__1m6_O {
    box-sizing: border-box;
    min-width: 0;
    margin: 0;
    padding: 10px 10px 20px;
}
.style_producttitle__1xREz {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin: 0 0 7px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.style_productprices__2V2BK {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.style_productpercentprice__2Jn62{
    text-align: center;
    display:flex;
    flex-direction: column;
}
.style_productpercent__1wpX8{
    font-size: 13px;
    font-weight: 400;
    color: #FFB369;
}
.style_productprice__3wxFD {
    font-size: 15px;
    font-weight: 700;
    color: #009E7F;
}
.style_productpointquantity__1VUy7{
    margin-top: 5px;
    justify-content: space-between;
    display:flex;
    vertical-align: bottom;
}
.style_productquantity__30rDL {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    vertical-align: bottom;
    margin-top: auto;
    margin-bottom: 1px;
}
.style_productpoint__3Seys {
    font-family: Lato,sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #009E7F;
    vertical-align: bottom;
    margin-top: auto;
}
.style_byhaCB__3fxKU {
    border: 1px solid #019376;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: inherit;
    background-color: #fff;
    color: #019376;
    width: 50px;
}

.style_jNqNmP__1Uto5 {
    font-family: Lato,sans-serif;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
}
/* сагсанд сонгогдсон бүтээгдэхүүний хэлбэржүүлэлт */
.style_hgBplF__2QBaC {
    display: flex;
    background-color: #009E7F;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 200px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    width: 50px;
    top: 0;
    right: 0;
}
.style_jJUnxI__2x71i {
    border: none;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 7px;
    cursor: pointer;
    font-size: 8px;
}
.style_gSFvVa__2W5GH {
    font-family: Lato,sans-serif;
    pointer-events: none;
    font-size: 12px;
}
.style_colorred__1mmzB{
    color: red;
}
.style_black__3SsWJ{
    color: #0D1136
}
/* бүтээгдэхүүн */
body {
    background: #F7F7F7;
}
.style_producttitle__kH7Li {
  font-family: Lato,sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #0D1136;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.style_grPQmX__2jKuX {
    margin: 5px 5px;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(1,minmax(180px,1fr));
}
@media screen and (min-width: 375px){
  .style_grPQmX__2jKuX {
    grid-template-columns: repeat(2,minmax(180px,1fr));
  }
}
@media screen and (min-width: 630px){
  .style_grPQmX__2jKuX {
    grid-template-columns: repeat(3,minmax(180px,1fr));
    margin: 7px 7px;
    grid-gap: 5px;
  }
}
@media screen and (min-width: 900px){
  .style_grPQmX__2jKuX {
    grid-template-columns: repeat(4,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1200px){
  .style_grPQmX__2jKuX {
    grid-template-columns: repeat(5,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1500px){
  .style_grPQmX__2jKuX {
    grid-template-columns: repeat(6,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1800px){
  .style_grPQmX__2jKuX {
    grid-template-columns: repeat(7,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
.style_ejsBAM__1pPP9 {
    box-sizing: border-box;
    min-width: 0px;
    margin: 20px 0px 0px;
    text-align: center;
}
.style_iZvlvx__2ayrf {
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
            text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px 15px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    -webkit-appearance: none;
            appearance: none;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    height: 38px;
    text-decoration: none;
    font-family: inherit;
    color: rgb(0, 158, 127);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(241, 241, 241);
    font-size: 14px;
    display: inline-flex;
}
.style_iZvlvx__2ayrf:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 158, 127);
    border-color: rgb(0, 158, 127);
}
.style_loadmore__2ehgz{
    margin: 0px 10px;
}
.style_loader__1O37o {
    color: #009e7f;
    font-size: 14px;
    font-weight:bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__leRoH 1.7s infinite ease, style_round__P0fkS 1.7s infinite ease;
    animation: style_load6__leRoH 1.7s infinite ease, style_round__P0fkS 1.7s infinite ease;
}
.style_center__3QjNB{
  padding-bottom: 50px;
}
.style_loader2__1u2ng {
    color: #009e7f;
    font-size: 60px;
    font-weight:bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__leRoH 1.7s infinite ease, style_round__P0fkS 1.7s infinite ease;
    animation: style_load6__leRoH 1.7s infinite ease, style_round__P0fkS 1.7s infinite ease;
}
@-webkit-keyframes style_load6__leRoH {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes style_load6__leRoH {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes style_round__P0fkS {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes style_round__P0fkS {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

.search-box {
  border: none;
  border-bottom: 1px solid #009E7F;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #009E7F;
}
@media screen and (min-width: 1200px){
  .search-box {
    line-height: 20px;
    font-size: 16px;
    color: red;
  }
}
.search-box:focus {
  outline: none;
}
main {
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Merriweather Sans", sans-serif;
}
main #errorText {
  font-size: 22px;
  margin: 14px 0;
}
main #errorLink {
  font-size: 20px;
  padding: 12px;
  border: 1px solid;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
main #errorLink:hover, main #errorLink:active {
  color: #fff;
  background: #000;
}
main #g6219 {
  -webkit-transform-origin: 85px 4px;
          transform-origin: 85px 4px;
  -webkit-animation: an1 12s 0.5s infinite ease-out;
          animation: an1 12s 0.5s infinite ease-out;
}

@-webkit-keyframes an1 {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  5% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  15% {
    -webkit-transform: rotate(-2.5deg);
            transform: rotate(-2.5deg);
  }
  25% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  35% {
    -webkit-transform: rotate(-1.5deg);
            transform: rotate(-1.5deg);
  }
  45% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
  }
  55% {
    -webkit-transform: rotate(-1.5deg);
            transform: rotate(-1.5deg);
  }
  65% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  75% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  85% {
    -webkit-transform: rotate(2.5deg);
            transform: rotate(2.5deg);
  }
  95% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes an1 {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  5% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  15% {
    -webkit-transform: rotate(-2.5deg);
            transform: rotate(-2.5deg);
  }
  25% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  35% {
    -webkit-transform: rotate(-1.5deg);
            transform: rotate(-1.5deg);
  }
  45% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
  }
  55% {
    -webkit-transform: rotate(-1.5deg);
            transform: rotate(-1.5deg);
  }
  65% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  75% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  85% {
    -webkit-transform: rotate(2.5deg);
            transform: rotate(2.5deg);
  }
  95% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
.style_rub__2mLEm {
  display: flex;
  flex-direction: column;
  color: #009e7f;
}
.style_rub2__2bl7s {
  display: flex;
  flex-direction: row;
}
.style_dateP__1y1AW {
  font-size: 10px;
  margin-bottom: 0px;
}
.style_dada__13WBb {
  display: flex;
  flex-direction: column;
}
.style_haha__2aRAC {
  font-size: small;
  margin-left: 5px;
  margin-bottom: 5px;
}

.style_loader__26tcm {
  color: #009e7f;
  font-size: 14px;
  font-weight:bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__3vL0b 1.7s infinite ease, style_round__-yFUR 1.7s infinite ease;
  animation: style_load6__3vL0b 1.7s infinite ease, style_round__-yFUR 1.7s infinite ease;
}
.style_center__3frDj{
padding-bottom: 50px;
}
.style_loader2__31iVD {
  color: #009e7f;
  font-size: 60px;
  font-weight:bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__3vL0b 1.7s infinite ease, style_round__-yFUR 1.7s infinite ease;
  animation: style_load6__3vL0b 1.7s infinite ease, style_round__-yFUR 1.7s infinite ease;
}
@-webkit-keyframes style_load6__3vL0b {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_load6__3vL0b {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes style_round__-yFUR {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes style_round__-yFUR {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.style_cardList__1EQKh {
  margin: 5px 5px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(1, minmax(180px, 1fr));
}
/* @media (max-width: 575px) {
  .cardList { 
    grid-template-columns: auto auto;
  }
} */
@media screen and (min-width: 375px) {
  .style_cardList__1EQKh {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
  }
}
@media screen and (min-width: 630px) {
  .style_cardList__1EQKh {
    grid-template-columns: repeat(3, minmax(180px, 1fr));
    margin: 7px 7px;
    grid-gap: 5px;
  }
}
@media screen and (min-width: 900px) {
  .style_cardList__1EQKh {
    grid-template-columns: repeat(4, minmax(180px, 1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
/* @media screen and (min-width: 1200px){
  .cardList {
    grid-template-columns: repeat(5,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1500px){
  .cardList {
    grid-template-columns: repeat(6,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
} */
.style_loader__1NZFR {
  color: #009e7f;
  font-size: 14px;
  font-weight: bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__29Gv3 1.7s infinite ease, style_round__356HN 1.7s infinite ease;
  animation: style_load6__29Gv3 1.7s infinite ease, style_round__356HN 1.7s infinite ease;
}

.style_deletebtn__1Qd0J {
  border:none;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: inherit;
    background-color: #fff;
    color: #d9534f;
    width: 50px;
}
.style_cardContainer__2xhpW {
  /* border: 1px solid gray; */
  position: relative;
  width: 90%;
  color: black; 
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
  transition: 0.3s;display: inline-block;
  padding: 10px;
  cursor: pointer;
  transition: -webkit-transform 0.25 ease-out;
  transition: transform 0.25 ease-out;
  transition: transform 0.25 ease-out, -webkit-transform 0.25 ease-out;
  margin: 10px;
  height: 180px;
}
  
.style_cardContainer__2xhpW img {
  object-fit: cover;
  object-position: center;
}
.style_cardContainer__2xhpW:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

 .style_text__1kRQb{
  color: rgb(13, 17, 54);

  text-align: center;
  /* text-transform: capitalize; */
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  overFlow: hidden;
  font-size: 18px;
  font-style: bold;
  font-weight: 700;
  font-family: Lato,sans-serif;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* white-space: nowrap; */
}
@media screen and (max-width: 375px){
  .style_text__1kRQb {
    font-size: 12px;
  }
}
.style_kOmOCE__3fup6 {
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    border-radius: 6px;
    background-color: #F7F7F7;
    border: 1px solid #F7F7F7;
    margin-bottom: 15px;
    margin-right: 15px;
    position: relative;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0D1136;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    transition: all 0.25s ease;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 15px;
}
.style_kOmOCE__3fup6.style_active__1PaJA {
    border: 1px solid #009E7F;
    background-color: #ffffff;
}
.style_kOmOCE__3fup6 input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.style_kOmOCE__3fup6:hover .style_jTaKCq__3x8Z8 {
    opacity: 1;
    visibility: visible;
}
.style_jNeWVx__1WivL {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #0D1136;
    line-height: 1.2;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.style_hsUQDG__2ynad {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #424561;
}
.style_jTaKCq__3x8Z8 {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
}
.style_icjzhS__3R_ID.style_editbtncheckout__1L__o {
    background-color: #009E7F;
}
.style_icjzhS__3R_ID.style_deletebtncheckout__vJI-_ {
    background-color: #ff5b60;
}
.style_icjzhS__3R_ID {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 5px;
    cursor: pointer;
    outline: 0;
    padding: 0;
    color: #ffffff;
}
.style_icjzhS__3R_ID i {
    display: block;
    font-size: 8px;
    height: auto;
}
.style_bCfOXr__NaKac {
    background-color: #ffffff;
    padding: 30px;
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
}
@media (max-width: 767px){
    .style_bCfOXr__NaKac {
        padding: 20px;
    }
}
.style_iDDlIV__1Pp6q {
    font-family: Lato,sans-serif;
    font-weight: 400;
    color: #0D1136;
    line-height: 1.2;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
}
@media (max-width: 767px){
    .style_iDDlIV__1Pp6q {
        font-size: 19px;
    }
}
@media (max-width: 600px){
    .style_iDDlIV__1Pp6q::before {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
}
.style_iDDlIV__1Pp6q:before {
    counter-increment: section-counter;
    content: counter(section-counter);
    color: #ffffff;
    font-size: 19px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009E7F;
    margin-right: 15px;
}
@media (max-width: 600px){
    .style_iDDlIV__1Pp6q:before {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
}
.style_dWZUxg__33Y34 {
    display: flex;
    flex-direction: column;
}
.style_leCXcG__mMx3h {
    display: flex;
    flex-wrap: wrap;
}
/* .dWZUxg label {
    -webkit-flex: calc(33.333333333% - 10px);
    -ms-flex: calc(33.333333333% - 10px);
    flex: calc(33.333333333% - 10px);
    max-width: calc(33.333333333% - 10px);
} */
.style_jNBJTJ__1mI8l {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0px;
    outline: 0px;
    border-radius: 0px;
    padding: 0px;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: rgb(0, 158, 127);
    position: absolute;
    top: 40px;
    right: 30px;
}
@media (max-width: 767px){
    .style_jNBJTJ__1mI8l {
        top: 27px;
        right: 20px;
    }
}
.style_hUFhpq__1JVAt {
    box-sizing: border-box;
    min-width: 0;
    margin: 0;
    margin-right: 8px;
}
.style_eTKfHA__12z5w {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(119, 121, 140);
    line-height: 1.5;
    margin-top: 30px;
    display: block;
}
.style_jZnGlQ__2TeCs {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(255, 91, 96);
    margin-left: 5px;
    cursor: pointer;
    line-height: 1.5;
}
.style_ExTwM__HGoiW {
    margin-top: 25px;
}
.style_ddKXqe__pRNwd {
    padding: 0px 30px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 158, 127);
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    -webkit-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    border: 0px;
    height: 48px;
    width: 100%;
}
.style_gwBSuY__3_nKP {
    width: 270px;
    flex-shrink: 0;
    padding-top: 20px;
}
@media (max-width: 1200px) and (min-width: 768px){
    .style_gwBSuY__3_nKP {
        width: 260px;
    }
}
@media (max-width: 767px){
    .style_gwBSuY__3_nKP {
        order: 0;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
        padding-bottom: 30px;
        position: relative !important;
    }
}
.style_kSbJGA__3pyd6 {
    width: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 990px) and (min-width: 768px){
    .style_kSbJGA__3pyd6 {
        padding-right: 15px;
    }
}
.style_dAPHLO__9kBCH {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    text-align: center;
    margin-bottom: 40px;
}

.style_checkoutscrollbar__2aAFQ {
    height: 100%;
    max-height: calc(100vh - 245px);
    max-height: 390px;
    padding-right: 15px;
    position: relative;
    overflow: auto !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}
@media (max-width: 767px){
    .style_checkoutscrollbar__2aAFQ{
        height: auto;
    }
}
.style_ospadding__2YNC7 {
    box-sizing: inherit;
    direction: inherit;
    overflow: auto;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
}
.style_hXnywk__Fo-Hv {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}
.style_fBhgXs__3IwCF {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
}
.style_iPCvVb__2GvfG {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 700;
    color: #0D1136;
}
.style_kZXmaV__3nZCX {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin: 0 12px;
}
.style_djStHc__2tlrg {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin-right: 15px;
}
.style_jDKalT__2Eg9g {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin-left: auto;
}

.style_vNVrf__3ZDph {
    border-top: 1px solid #E6E6E6;
    padding: 20px 15px 0;
    margin-top: 20px;
}
.style_iZImhr__ScEHL {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.style_kTPYoz__SLowP {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
}
.style_kxbXCM__BzjjL {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 700;
    color: #0D1136;
}
.style_bsOIsI__2_YpQ {
    margin-top: 25px;
}
.style_flexrow__4vIHx{
    display: flex;
    flex-direction: column;
}
.style_innerRndComponent__1lLvf {
    width: 100%;
    padding: 30px;
    height: auto;
    background-color: rgb(247, 248, 249);
    border: 0px;
    box-sizing: border-box;
    border-radius: 2px;
}
.style_bHlADF__3tyoX {
    font-family: sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 15px;
}
.style_deMkW__nSA8t {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.style_iapefQ__3cl9a {
    display: block;
    width: 100%;
    padding: 0 18px;
    -webkit-appearance: none;
    appearance: none;
    font-family: Lato,sans-serif;
    font-size: 15px;
    line-height: inherit;
    border: 1px solid;
    border-color: #f1f1f1;
    border-radius: 6px;
    background-color: #ffffff;
    color: #0D1136;
    height: 48px;
    transition: all 0.25s ease;
}
.style_iapefQ__3cl9a:hover, .style_iapefQ__3cl9a:focus {
    outline: 0;
}
.style_iapefQ__3cl9a:focus {
    border-color: #009E7F;
}
.style_bUSVGR__ycUWn textarea {
    height: auto;
    min-height: 171px;
    padding-top: 15px;
    resize: none;
}
.style_dwDYsr__2sl_- {
    width: 100%;
    height: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
    background-color: #009E7F;
    /* -webkit-transition: all 0.3s ease; */
    transition: all 0.3s ease;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    -webkit-appearance: none;
            appearance: none;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: flex;
    /* -webkit-align-items: center; */
    align-items: center;
    /* -webkit-justify-content: center; */
    justify-content: center;
    /* -webkit-flex-shrink: 0; */
    flex-shrink: 0;
    text-align: center;
    /* height: 38px; */
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: inherit;
    border: 0;
}
.style_reuseModalCloseBtn__18GmK {
    right: 10px!important;
    background-color: #ffffff!important;
    color: #222222!important;
    border-radius: 15px!important;
    padding: 0 9px!important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
}

.style_reuseModalCloseBtn__18GmK {
    position: fixed;
    top: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 9999999;
}
.style_reuseModalCloseBtn__18GmK i{
    font-weight: 600;
}
.style_innerRndComponent__6xizv {
    width: 100%;
    padding: 30px;
    height: auto;
    background-color: rgb(247, 248, 249);
    border: 0px;
    box-sizing: border-box;
    border-radius: 2px;
}
.style_bHlADF__1U0-y {
    font-family: sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 15px;
}
.style_deMkW__31fI3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.style_iapefQ__gRXVL {
    display: block;
    width: 100%;
    padding: 0 18px;
    -webkit-appearance: none;
    appearance: none;
    font-family: Lato,sans-serif;
    font-size: 15px;
    line-height: inherit;
    border: 1px solid;
    border-color: #f1f1f1;
    border-radius: 6px;
    background-color: #ffffff;
    color: #0D1136;
    height: 48px;
    transition: all 0.25s ease;
}
.style_iapefQ__gRXVL:hover, .style_iapefQ__gRXVL:focus {
    outline: 0;
}
.style_iapefQ__gRXVL:focus {
    border-color: #009E7F;
}
.style_bUSVGR__JTKnJ textarea {
    height: auto;
    min-height: 171px;
    padding-top: 15px;
    resize: none;
}
.style_dwDYsr__3uUVl {
    width: 100%;
    height: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
    background-color: #009E7F;
    /* -webkit-transition: all 0.3s ease; */
    transition: all 0.3s ease;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    -webkit-appearance: none;
            appearance: none;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: flex;
    /* -webkit-align-items: center; */
    align-items: center;
    /* -webkit-justify-content: center; */
    justify-content: center;
    /* -webkit-flex-shrink: 0; */
    flex-shrink: 0;
    text-align: center;
    /* height: 38px; */
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: inherit;
    border: 0;
}
.style_reuseModalCloseBtn__1wonC {
    right: 10px!important;
    background-color: #ffffff!important;
    color: #222222!important;
    border-radius: 15px!important;
    padding: 0 9px!important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
}

.style_reuseModalCloseBtn__1wonC {
    position: fixed;
    top: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 9999999;
}
.style_reuseModalCloseBtn__1wonC i{
    font-weight: 600;
}
.style_kqrKyZ__2Xswy {
    background-color: #F7F7F7;
    height: 100%;
}
.style_dcTgel__Vsa6P {
    width: 100%;
    display: flex;
    position: relative;
}
@media (max-width: 990px){
    .style_kqrKyZ__2Xswy {
        background-color: #ffffff;
    }
}
@media (max-width: 1100px){
    .style_dcTgel__Vsa6P {
        padding-left: 0;
        padding-right: 0;
    }
}
.style_hGqnHy__3P_zT {
    width: 100%;
    display: flex;
    counter-reset: section-counter;
}
@media (max-width: 767px){
    .style_hGqnHy__3P_zT {
        flex-direction: column;
    }
}
@media (min-width: 990px){
    .style_hGqnHy__3P_zT {
        width: 970px;
        margin-left: auto;
        margin-right: auto;
    }
}
.style_hwlTmd__3HHQy {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    padding: 20px;
}
@media (max-width: 990px){
    .style_hwlTmd__3HHQy {
        margin-right: 10px;
    }
}
@media (max-width: 767px){
    .style_hwlTmd__3HHQy {
        order: 1;
        margin-right: 0;
    }
}
.style_loader__1iVww {
    color: #1ad15a;
    font-size: 40px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__36Nul 1.7s infinite ease, style_round__1dgyI 1.7s infinite ease;
    animation: style_load6__36Nul 1.7s infinite ease, style_round__1dgyI 1.7s infinite ease;
  }
  @-webkit-keyframes style_load6__36Nul {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_load6__36Nul {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes style_round__1dgyI {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes style_round__1dgyI {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .style_savebtn__32ULl {
    height: 48px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 193, 7);
    padding: 0px;
    border-radius: 48px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left: 15px;
    display: flex;
    font-weight: bold;
    color: white;
  }
.style_eTHeCj__3R0Yc {
    background-color: #F7F7F7;
    position: relative;
    padding: 10px 0 60px 0;
    min-height: 100vh;
}
.style_fZqxml__26teJ {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    padding: 60px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767px){
    .style_fZqxml__26teJ {
        padding: 50px 25px;
    }
}
@media (max-width: 990px){
    .style_fZqxml__26teJ {
        padding: 50px 45px;
    }
}
@media (min-width: 991px){
    .style_fZqxml__26teJ {
        width: 870px;
    }
}
.style_homebtn__1ZZGK {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    padding: 5px 15px;
    height: 36px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: 0.15s ease-in-out;
}
@media (max-width: 767px){
    .style_home-btn__2NpMP {
        font-size: 13px;
        height: 34px;
        padding: 5px 12px;
    }
}
.style_eIRhpR__BNOfe {
    margin-bottom: 60px;
}
@media (max-width: 767px){
    .style_eIRhpR__BNOfe {
        margin-bottom: 30px;
    }
}
.style_QVyIh__2Lyh7 {
    font-family: Poppins,sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #0D1136;
    line-height: 1;
    margin-bottom: 32px;
}
@media (max-width: 767px){
    .style_QVyIh__2Lyh7 {
        font-size: calc(15px + 1px);
        margin-bottom: 25px;
    }
}
.style_bvSdvR__1dE_Y {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_eIHWxM__2F-XA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
@media (max-width: 767px){
    .style_eIHWxM__2F-XA {
        flex-wrap: wrap;
    }
}
.style_gSskSI__2ijuK:first-child {
    padding-left: 0;
    border-left: 0;
}
.style_gSskSI__2ijuK {
    flex-grow: 1;
    padding: 0 15px;
    border-left: 1px solid #f1f1f1;
}
@media (max-width: 767px){
    .style_gSskSI__2ijuK {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding: 0;
        border: 0;
    }
}
.style_gSskSI__2ijuK .style_title__38ron {
    margin-bottom: 10px;
}
.style_bHcUtG__1Ru7x {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 700;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_bvSdvR__1dE_Y:last-child {
    color: #424561;
}
.style_bvSdvR__1dE_Y {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_dEojFW__1WTdh {
    margin-bottom: 60px;
}
@media (max-width: 767px){
    .style_dEojFW__1WTdh {
        margin-bottom: 50px;
    }
}
.style_hXfWwV__jBVtn {
    display: flex;
    margin-bottom: 20px;
}
.style_jHaRgo__3L2dr {
    flex-basis: 210px;
    max-width: 210px;
    flex-shrink: 0;
    position: relative;
}
@media (max-width: 767px){
    .style_jHaRgo__3L2dr {
        flex-basis: 105px;
        max-width: 105px;
    }
}
.style_jHaRgo__3L2dr:after {
    content: ':';
    position: absolute;
    top: -1px;
    right: -2px;
    line-height: 1;
}
.style_gADUYd__30VMh {
    padding-left: 90px;
}
@media (max-width: 767px){
    .style_gADUYd__30VMh {
        padding-left: 20px;
    }
}
.style_bvSdvR__1dE_Y:last-child {
    color: #424561;
}
.style_bvSdvR__1dE_Y {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_footerwarn__3Snf0{
    margin-bottom: 10px;
    padding: 10px 2px;
    border: 1px solid  #009e7f;
    border-radius: 5px;
}

.style_loader__2E1rH {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__1jclt 1.7s infinite ease, style_round__1ODVJ 1.7s infinite ease;
    animation: style_load6__1jclt 1.7s infinite ease, style_round__1ODVJ 1.7s infinite ease;
  }
  @-webkit-keyframes style_load6__1jclt {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_load6__1jclt {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes style_round__1ODVJ {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes style_round__1ODVJ {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
.style_mainDiv__3C_-0 {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  max-width: 400px;
  margin: 100px auto;
}
.style_errorMsg__Qa4yn {
  margin-left: 10px;
  color: red;
}
.style_loginBtn__3Nd84 {
  background-color: rgb(0, 158, 127);
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.style_alertMessage__1FSsZ
{
  color: #28a745;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.style_loader__2UqbR {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__1ku5X 1.7s infinite ease, style_round__1OpGA 1.7s infinite ease;
    animation: style_load6__1ku5X 1.7s infinite ease, style_round__1OpGA 1.7s infinite ease;
  }
  @-webkit-keyframes style_load6__1ku5X {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_load6__1ku5X {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes style_round__1OpGA {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes style_round__1OpGA {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
.style_mainDivReg__1eOAq {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  max-width: 500px;
  margin: 50px auto;
}
.style_errorMsg__2iExn {
  margin-left: 10px;
  color: red;
}
.style_registerBtn__tEGnt {
  background-color: #009e7f;
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.style_formInput__179jT {
  width: 100%;
}
.style_formInput__179jT label {
  position: relative;
  display: block;
  width: 100%;
  min-height: 45px;
}
.style_formInput__179jT .style_placeholder__2_sVy {
  position: absolute;
  display: block;
  top: 23px;
  z-index: 2;
  font-size: 16px;
  transition: all 200ms ease-in-out;
  width: 100%;
  cursor: text;
  color: #c3c3c3;
}
.style_formInput__179jT input,
.style_formInput__179jT textarea {
  position: absolute;
  top: 15px;
  z-index: 1;
  width: 100%;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid grey;
  transition: border-color 200ms ease-in-out;
  outline: none;
  padding: 0;
  margin: 0;
}
.style_formInput__179jT textarea {
  min-height: 30px;
  padding: 8px 0;
}
.style_formInput__179jT input {
  height: 30px;
}
.style_formInput__179jT input:focus,
.style_formInput__179jT input:valid,
.style_formInput__179jT textarea:focus,
.style_formInput__179jT textarea:valid {
  border-bottom: 2px solid #009e7f;
}
.style_formInput__179jT input:focus + .style_placeholder__2_sVy,
.style_formInput__179jT input:valid + .style_placeholder__2_sVy,
.style_formInput__179jT textarea:focus + .style_placeholder__2_sVy,
.style_formInput__179jT textarea:valid + .style_placeholder__2_sVy {
  top: 0;
  cursor: inherit;
  font-size: 14px;
  color: #009e7f;
}

/* webview item [start] */
.style_lihBKG__5EjcM {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}
.style_hjcFuj__3Kth_ {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
}
.style_hjcFuj__3Kth_ span {
    font-weight: 400;
}
.style_iTdekY__OWJcH {
    font-family: Lato,sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #2e70fa;
    line-height: 1;
    background-color: rgba(46,112,250,0.1);
    padding: 10px 5px;
    border-radius: 6px;
}
.style_guZSJO__2uD8d {
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.style_Bmugg__3SiFw {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #0D1136;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}
.style_Bmugg__3SiFw.style_price__3bBRK {
    font-weight: 700;
} 
.style_Bmugg__3SiFw:last-child {
    margin-bottom: 0;
}
.style_font11__3CjT3{
    font-size: 11px;
}
/* webview item end */

/* webview detai start */
.style_cJGveh__aBrVT {
    width: 100%;
    min-height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f1;
}
@media only screen and (max-width: 1199px){
    .style_cJGveh__aBrVT {
        height: 657px;
    }
}
.style_jjWgRb__3ACpH {
    width: 100%;
    display: flex;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
}
.style_bLiRNB__2Mj-W {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #f1f1f1;
    padding: 20px;
}
.style_bLiRNB__2Mj-W h3 {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 10px;
}
.style_fbmTwo__3DdEj {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    line-height: 1.5;
}
.style_bPJmao__3n6sh {
    width: 235px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 20px;
}
.style_cqjOZT__3tOLR {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.style_cFTfrZ__3yLeg {
    color: #0D1136;
}
.style_cqjOZT__3tOLR.style_grandTotal__2xS3Y {
    font-weight: 700;
    color: #0D1136;
}
.style_cqjOZT__3tOLR:last-child {
    margin-bottom: 0;
}
.style_lbTChn__3VO2V {
    width: 100%;
    display: flex;
    padding: 30px 25px;
    border-bottom: 1px solid #f1f1f1;
}
.style_foKLRz__ZeAbt {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}
.style_foKLRz__ZeAbt:first-child .style_progressboxstyle__lGJJe {
    width: 50%;
    left: 50%;
}
.style_foKLRz__ZeAbt:last-child .style_progressboxstyle__lGJJe{
    width: 50%;
}
.style_jYdTsb__3XYuW {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 15px; */
}
/* progress bar */
.style_flwWPp__3pJcx {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #009E7F;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;
    border: 1px dashed #009E7F;
}
.style_fRVGLf__3RwOx {
    width: 13px;
    height: 17px;
}
.style_fRVGLf__3RwOx i {
    width: 100%;
}
.style_wHIWv__3o-3v {
    width: 100%;
    height: 4px;
    background-color: #E6E6E6;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -2px;
}
.style_kNirdD__k-NXo {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.style_fcCkVG__2RLPi {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    text-align: center;
    padding: 0 8px;
}
.style_checked__3Zsvw {
    background-color: #009E7F;
    color: #ffffff;
}
.style_checked2__36VQ8 {
    background-color: #9c2917;
    color: #000;
}
.style_rctable__2yHWJ {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
    height: 350px;
}
.style_cAgIfa__2Ysmi {
    border-collapse: collapse;
}
.style_rctable__2yHWJ table {
    border-spacing: 0px;
    width: 100%;
}
.style_rctable__2yHWJ th, .style_rctable__2yHWJ td {
    padding: 0;
    position: relative;
    border: 1px solid red;
    border-top: 0;
    border-left: 0;
    transition: box-shadow 0.3s;
    padding: 16px 8px;
    box-sizing: border-box;
    white-space: normal;
    word-break: break-word;
}
.style_cAgIfa__2Ysmi thead th:first-child {
    padding-left: 110px;
    text-align: left;
}
.style_cAgIfa__2Ysmi thead th:last-child {
    text-align: right;
}
.style_cAgIfa__2Ysmi thead th {
    padding: 8px 20px;
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #0D1136;
    border: none;
}
.style_rctable__2yHWJ thead td, .style_rctable__2yHWJ thead th {
    background: #f7f7f7;
    text-align: center;
}
.style_cAgIfa__2Ysmi tr td {
    /* padding: 20px; */
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0D1136;
    border-bottom: 0;
    border: none;
}
.style_lhXkXj__2KqG5 {
    display: flex;
    align-items: center;
}
.style_kONoVC__2058J {
    width: 75px;
    height: 75px;
    display: flex;
    flex-shrink: 0;
}
.style_kONoVC__2058J img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.style_iVTGVp__1HcHW {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    overflow: hidden;
}
.style_kIgOkd__2Ee7r {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.style_bnnPwi__1_TOR {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    margin-bottom: 5px;
}
.style_kcpNyZ__1FI59 {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #009E7F;
}
.style_tdright__12EkP{
    text-align: right;
}
.style_tdright__12EkP p{
    margin: 0px;
}
.style_notorder__38FMS{
    padding: 20px;
    color: red;
}
/* webview detail end */

/* mobile view start */
.style_fDCATW__32eMH {
    display: none;
}
@media only screen and (max-width: 989px){
    .style_fDCATW__32eMH {
        width: 100%;
        display: flex;
    }
}
.style_bGLdjy__hBw7Q {
    width: 100%;
    padding: 0 20px 20px;
}
@media (max-width: 767px){
    .style_bGLdjy__hBw7Q {
        padding: 0;
    }
}
.style_rccollapse__23Gi4 {
    background-color: transparent;
    border-radius: 0;
    border: 0;
}
.style_rccollapse__23Gi4 > .style_rccollapseitem__3rIU6 {
    margin-bottom: 15px;
    background-color: #F7F7F7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex-shrink: 0;
    border: 1px solid transparent;
}
.style_bGLdjy__hBw7Q .style_rccollapse__23Gi4 > .style_rccollapseitem__3rIU6.style_rccollapseitemactive__3K_iO {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
}
.style_rccollapsecontentinactive__3zsf9 {
    display: none;
}
.style_rccollapse__23Gi4 > .style_rccollapseitem__3rIU6 > .style_rccollapseheader__3rE0i {
    padding: 0;
    outline: 0;
    display: flex;
    align-items: center;
    line-height: 22px;
    color: #666;
    cursor: pointer;
}
.style_rccollapse__23Gi4 > .style_rccollapseitem__3rIU6 > .style_rccollapseheader__3rE0i .style_arrow__1Z1al {
    display: inline-block;
    content: '\20';
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid #666;
    vertical-align: middle;
    margin-right: 8px;
}
.style_hzdtju__2pS5E {
    width: 100%;
}
.style_rccollapse__23Gi4 > .style_rccollapseitem__3rIU6 .style_rccollapsecontent__3Qbc2 {
    padding: 0px;
}

.style_rccollapsecontent__3Qbc2 {
    overflow: hidden;
    color: #666;
    padding: 0 16px;
    background-color: #fff;
}
.style_rccollapse__23Gi4 > .style_rccollapseitem__3rIU6 .style_rccollapsecontent__3Qbc2 > .style_rccollapsecontentbox__1jwDk {
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
}
.style_bmogfF__8fTzA {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.style_eUHqXZ__2aGyO {
    flex-direction: column;
}
.style_cwZtQI__2VscJ {
    border-bottom: 1px solid #f1f1f1;
    border-right: 0;
}
.style_fgcIfk__1KPEf {
    width: 100%;
}
@media (max-width: 767px){
    .style_hALMhu__1sXs_ {
        flex-direction: column;
    }
    .style_foKLRz__ZeAbt {
        flex-direction: row;
        margin-bottom: 30px;
    }
    .style_jYdTsb__3XYuW {
        width: auto;
        margin-bottom: 0;
    }
    .style_foKLRz__ZeAbt:first-child .style_progressboxstyle__lGJJe {
        width: 3px;
        height: 100%;
        top: 50%;
    }
    .style_foKLRz__ZeAbt:last-child .style_progressboxstyle__lGJJe{
        width: 3px;
        height: 100%;
        top: -50%;
    }
    .style_kNirdD__k-NXo {
        margin-top: 0px;
        width: 100%;
        align-items: flex-start;
        margin-left: 20px;
    }
    .style_fcCkVG__2RLPi {
        padding: 0;
    }
    .style_wHIWv__3o-3v {
        width: 3px;
        height: 200%;
        left: 50%;
        top: -50%;
        margin-top: 0;
        margin-left: -1px;
    }
}
@media (max-width: 600px){
    .style_rctable__2yHWJ table{
        width: 560px;
    }
}
/* mobile view end */
/* web view */
.style_eXUcye__17qhD {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    padding: 10px 70px 40px;
}
@media only screen and (min-width: 991px) and (max-width: 1280px){
    .style_eXUcye__17qhD {
        padding: 10px 15px 60px;
    }
}
@media only screen and (max-width: 990px){
    .style_eXUcye__17qhD {
        padding: 10px 0 60px;
    }
}
.style_TVBQD__3VfgA {
    width: calc(100% - 330px);
    display: flex;
    align-items: flex-start;
    margin: auto;
}
@media only screen and (max-width: 1199px){
    .style_TVBQD__3VfgA {
        width: 100%;
        padding: 0 20px;
    }
}
.style_duuioQ__1f7ni {
    display: none;
}
@media only screen and (min-width: 990px){
    .style_duuioQ__1f7ni {
        display: flex;
    }
}
.style_fgelJT__bXYTJ {
    width: 330px;
    height: 657px;
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f1;
    flex-shrink: 0;
    margin-right: 30px;
}
@media only screen and (max-width: 1199px){
    .style_fgelJT__bXYTJ {
        height: 657px;
        width: 310px;
        margin-right: 20px;
    }
}
.style_kVstku__aVyvl {
    font-family: Poppins,sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #0D1136;
    margin: 25px 0;
    padding: 0px 20px;
}
.style_order-scrollbar__2J4df {
    height: 100%;
    min-height: 420px;
}
.style_bGLdjy__13tZQ {
    width: 100%;
    padding: 0 20px 20px;
    overflow: auto;
}
.style_dEBCwJ__36KXE {
    background-color: #F7F7F7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex-shrink: 0;
    border: 2px solid transparent;
}
.style_dEBCwJ__36KXE.style_active__IcLOo {
    border: 2px solid #009E7F;
}
.style_dEBCwJ__36KXE input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.style_kqrKyZ__1ITtb {
  background-color: #f7f7f7;
  height: 100vh;
}
.style_dcTgel__1Z9iq {
  width: 100%;
  display: flex;
  /* padding: 30px 60px 60px; */
  position: relative;
}
@media (max-width: 990px) {
  .style_dcTgel__1Z9iq {
    /* padding-top: 60px; */
  }
  .style_kqrKyZ__1ITtb {
    background-color: #ffffff;
  }
}
@media (max-width: 1100px) {
  .style_dcTgel__1Z9iq {
    padding-left: 0;
    padding-right: 0;
  }
}
.style_hGqnHy__3pS1e {
  width: 100%;
  display: flex;
  counter-reset: section-counter;
}
@media (max-width: 767px) {
  .style_hGqnHy__3pS1e {
    flex-direction: column;
  }
}
@media (min-width: 990px) {
  .style_hGqnHy__3pS1e {
    width: 970px;
    margin-left: auto;
    margin-right: auto;
  }
}
.style_hwlTmd__1nSsn {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  padding: 20px;
}
@media (max-width: 990px) {
  .style_hwlTmd__1nSsn {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .style_hwlTmd__1nSsn {
    order: 1;
    margin-right: 0;
  }
}

.style_gwBSuY__2TzuL {
  width: 350px;
  flex-shrink: 0;
  padding-top: 20px;
}
@media (max-width: 1200px) and (min-width: 768px) {
  .style_gwBSuY__2TzuL {
    width: 260px;
  }
}
@media (max-width: 767px) {
  .style_gwBSuY__2TzuL {
    order: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    position: relative !important;
  }
}
.style_kSbJGA__3JnTl {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 990px) and (min-width: 768px) {
  .style_kSbJGA__3JnTl {
    padding-right: 15px;
  }
}
.style_dAPHLO__1vDqh {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #0d1136;
  text-align: center;
  margin-bottom: 40px;
}
.style_colDIV__3cs5H {
  display: flex;
  flex-direction: row;
}
.style_colDIV2__23sMg {
  display: flex;
  flex-direction: row;
}
.style_phoneLbl__13PRI {
  font-size: 15px;
}
.style_subBtn__Egrz3 {
  background-color: #009e7f;
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-top: 20px;
}

.style_mainDiv__2jNFw {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    width: 90%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1.1rem;
    max-width: 400px;
    margin: 100px auto;
  }
  .style_errorMsg__2ihcP {
    margin-left: 10px;
    color: red;
  }
  .style_loginBtn__26CkV {
    background-color: rgb(0, 158, 127);
    color: white;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  .style_alertMessage__3j7Oj
  {
    color: #28a745;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
.style_errorMsg__21NRd{
    color: red;
}
.style_loader__2B3dJ {
color: #1ad15a;
font-size: 60px;
text-indent: -9999em;
overflow: hidden;
width: 1em;
height: 1em;
border-radius: 50%;
margin: 20% auto;
position: relative;
-webkit-transform: translateZ(0);
transform: translateZ(0);
-webkit-animation: style_load6__-n43u 1.7s infinite ease, style_round__1N_e9 1.7s infinite ease;
animation: style_load6__-n43u 1.7s infinite ease, style_round__1N_e9 1.7s infinite ease;
}
@-webkit-keyframes style_load6__-n43u {
0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
5%,
95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
10%,
59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
}
20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
}
38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
}
100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
}
@keyframes style_load6__-n43u {
0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
5%,
95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
10%,
59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
}
20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
}
38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
}
100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
}
@-webkit-keyframes style_round__1N_e9 {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@keyframes style_round__1N_e9 {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
} 
.style_CardBodyImg__qca-4{
    height: 150px;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    /* transform: scale(1.4); */
    /* transform-origin: 100% 0; */
    align-items: center;
}
.style_CardBodyImg__qca-4:hover {
    -webkit-transform: scale(2.5);
            transform: scale(2.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.style_CardBodyImgTd__387U9{
    width: 20%;
    text-align: center;
}

.style_CardBodyEdit__1Wfvd{
    position: relative;
    width: 3%;
    text-align: center;
    align-items: center;
}

.style_CardIconCenter__2llZf {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


.style_loader__3IcUT {
  color: #1ad15a;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load6__18BjJ 1.7s infinite ease, style_round__LNcq8 1.7s infinite ease;
  animation: style_load6__18BjJ 1.7s infinite ease, style_round__LNcq8 1.7s infinite ease;
}
@-webkit-keyframes style_load6__18BjJ {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_load6__18BjJ {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes style_round__LNcq8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes style_round__LNcq8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.style_loader__3R9N0 {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__3_dD3 1.7s infinite ease, style_round__1yrRq 1.7s infinite ease;
    animation: style_load6__3_dD3 1.7s infinite ease, style_round__1yrRq 1.7s infinite ease;
  }
  @-webkit-keyframes style_load6__3_dD3 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_load6__3_dD3 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes style_round__1yrRq {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes style_round__1yrRq {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
.style_marginRight__2b2wr {
    margin: 0px 10px 0px 0px;
  }
  .style_errorMsg__24Elp {
    margin-left: 10px;
    color: red;
  }
  
  .style_inputImage__1DGYf {
    height: 50px;
  }
  .style_productPrice__3ufVw {
    display: flex;
    flex-direction: row;
  }
  .style_productPrice__3ufVw input {
    width: 200px;
  }
  
.style_home__1t_vx {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  
  .style_page1__3vHgk {
    height: 100vh;
    min-height: 400px;
    width: 60%;
    text-align: center;
    background: white;
  }
  
  .style_page2__1CYVv {
    height: 100%;
    text-align: center;
    width: 60%;
    background: white;
  }
  
  .style_page3__3UoWS {
    height: 100%;
    text-align: center;
    width: 60%;
    background: white;
  }

  #style_root__15AFp nav{
      display: none;
  }
  
  .style_loader__lEz8x {
    color: #fff;
    font-size: 14px;
    font-weight: bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 5px 10px;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__3b6GC 1.7s infinite ease, style_round__JBLdB 1.7s infinite ease;
    animation: style_load6__3b6GC 1.7s infinite ease, style_round__JBLdB 1.7s infinite ease;
  }
  
  .style_cont__ut8oe {
    background: url(https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80)
      50% -0px;
    background-size: cover;
    width: 100vw;
    height: 100vh;
  }
  .style_contdiv1__1wbL_{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(255, 255, 255);
  }
  .style_contdiv1__1wbL_ img{
    width: 64px;
    margin-left: 64px;
    border-radius: 5px;
  }
  .style_contdiv1__1wbL_ ul{
    display: flex;
    list-style: none;
    margin-right: 50px;
  }
  .style_contdiv1__1wbL_ li{
    padding: 30px 10px;
    cursor: pointer;
  }
  .style_contdiv1__1wbL_ p{
    color: rgb(255, 255, 255);
  }
  .style_header__32Sb9 {
    display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 600px) {
    .style_page1__3vHgk {
      height: 100vh;
      width: 100%;
      text-align: center;
      background: white;
    }
    .style_page2__1CYVv {
      height: 100vh;
      width: 100%;
      text-align: center;
      background: white;
    }
    .style_page3__3UoWS {
      height: 100vh;
      width: 100%;
      background: white;
      text-align: center;
    }
  }
  
  @-webkit-keyframes style_load6__3b6GC {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_load6__3b6GC {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  
  
  @-webkit-keyframes style_round__JBLdB {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes style_round__JBLdB {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
.style_dHQHGmImg__119H0 {
    width: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* 
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
} */

@media (max-width: 600px) {
    .style_dHQHGmImg__119H0 {
        width: 100%;
        height:100%;;
        text-align: center;
        align-content: center;
        text-align: center;
        align-items: center;
    }
}
.style_dHQHGmImg__2kpum {
    width: 50%;
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 600px) {
    .style_dHQHGmImg__2kpum {
        width: 100%;
        height: 100vh;
        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.style_errorMsg__w4rhM {
    color: red;
}

.style_loader__jCs02 {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: style_load6__sPbHJ 1.7s infinite ease, style_round__1rdJm 1.7s infinite ease;
    animation: style_load6__sPbHJ 1.7s infinite ease, style_round__1rdJm 1.7s infinite ease;
}

@-webkit-keyframes style_load6__sPbHJ {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes style_load6__sPbHJ {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes style_round__1rdJm {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes style_round__1rdJm {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.style_inputImage__11mDa {
    height: 50px;
}
.style_btnHidden__icZs_ {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.style_divBtnHide__1io_o {
  display: flex;
  flex-direction: row;
}
.style_btnHidden__icZs_ i {
  color: darkgreen;
  font-size: 14px;
}
.style_colorred__2G1Se{
  font-weight: bold;
  color: #CC0000;
}
.style_colorgreen__cArUv{
  font-weight: bold;
  color: #00CC00;
}
